var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isChapter: false,
        isForm: _vm.isForm,
        btnLoading: _vm.btnLoading,
        permission: _vm.parent.isTongChou
          ? "production:stage:button:edit"
          : "production:stage:button:edit:isTongChou",
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c("a-table", {
            attrs: {
              rowKey: "id",
              rowSelection: _vm.rowSelection,
              columns: _vm.columns,
              dataSource: _vm.dataSource,
            },
            on: { change: _vm.handleTableChange },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }