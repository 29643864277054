<template>
  <drawer-layout
    :title="setTitle"
    :show="open"
    :isChapter="false"
    :isForm="isForm"
    :btnLoading="btnLoading"
    :permission="parent.isTongChou ? 'production:stage:button:edit' : 'production:stage:button:edit:isTongChou'"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <!-- {{ { curRow } }} -->
    <div slot="form">
      <a-table
        rowKey="id"
        :rowSelection="rowSelection"
        @change="handleTableChange"
        :columns="columns"
        :dataSource="dataSource"
      ></a-table>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import TranslateLevel from '@/views/system/modules/rule/translate-level.vue'
import TranslateCheck from '@/views/system/modules/rule/translate-check.vue'
import TranslateCoefficient from '@/views/system/modules/rule/translate-coefficient.vue'
import TranslateDifficulty from '@/views/system/modules/rule/translate-difficulty'
import EditLevelForm from '@/components/edit/form-list/edit-level-form.vue'
import EditAssessForm from '@/components/edit/edit-assessRule-form.vue'
import EditMoneyForm from '@/components/edit/edit-money-form.vue'
import EditCoefficientForm from '@/components/edit/edit-coefficient-form.vue'
import EditFactorForm from '@/components/edit/edit-factor-form.vue'
import EditKpiForm from '@/components/edit/edit-kpi-form.vue'
import EditCofForm from '@/components/edit/edit-cof-form.vue'
import { postAction, getAction } from '@/api/manage'
export default {
  mixins: [DrawerMixins],
  props: {
    id: [Number, String],
    productionId: [Number, String],
    parent: {
      type: Object,
      default: () => ({})
    },
    /*     allRuleList: {
      type: Array,
      default: () => []
    }, */
    adminUsers: {
      type: Array,
      default: () => []
    }
  },
  components: {
    TranslateLevel,
    TranslateCheck,
    TranslateCoefficient,
    TranslateDifficulty,
    EditLevelForm,
    EditAssessForm,
    EditMoneyForm,
    EditCoefficientForm,
    EditFactorForm,
    EditKpiForm,
    EditCofForm
  },
  data() {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 60
        },
        {
          title: '阶段名称',
          dataIndex: 'stageName'
        },
        {
          title: '人员职位',
          dataIndex: 'positionName'
        }
      ],
      visible: false,
      radioValue: 1,
      selectedRowKeys: [],
      selectionRows: [],
      dataSource: [],
      allRuleList: [],
      activeKey: '',
      applyTranslation: false,
      applyReview: false,
      rejectList: [],
      positionId: '',
      kpiList: [],
      defaultUser: [],
      defaultAssUser: [],
      color: '',
      rules: {
        platformName: [{ required: true, message: '请输入平台名称', trigger: 'blur' }]
      },
      ruleList: [],
      url: {
        edit: '/production/edit_stage',
        add: '/production/add_stage'
      },
      labelWidth: '90',
      layout: {
        labelCol: { style: 'width: 90px;', span: 4 },
        wrapperCol: { span: 20 }
      }
    }
  },
  computed: {
    rowSelection() {
      return {
        hideDefaultSelections: false,
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      }
    }
  },
  mounted() {
    setTimeout(() => {
      for (let css of document.styleSheets) {
        for (let rule of css.cssRules) {
          if (rule.cssText?.includes('.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected')) {
            this.color = rule.style.backgroundColor
          }
        }
      }
    }, 150)
  },
  methods: {
    onSelectChange(selectedRowKeys, selectionRows) {
      // console.log(selectedRowKeys,selectionRows,"onSelectChange")
      this.selectedRowKeys = selectedRowKeys
      this.selectionRows = selectionRows
      console.log(this.selectedRowKeys, this.selectionRows)
    },
    handleTableChange(pagination, filters, sorter) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      this.savePageSize2LS(this.url.list, pagination.pageSize)
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field
        this.isorter.order = 'ascend' == sorter.order ? 'asc' : 'desc'
      }
      this.ipagination = pagination
      this.loadData()
    },
    stageChange(v) {
      let stage = this.allRuleList.filter(one => one.stageName == this.stageList.find(s => s.id == v).stageName)
      if (stage.length > 0) {
        /*         this.formData.positionId = stage[0].positionId
        this.formData.sequenceNo = stage[0].sequenceNo */
        this.formData.applyTranslation = stage[0].applyTranslation
        this.applyTranslation = stage[0].applyTranslation ? true : false
        this.formData.applyReview = stage[0].applyReview
        this.applyReview = stage[0].applyReview ? true : false
      }
      this.ruleList = []
      this.allRuleList.forEach(item => {
        if (item.stageId == this.formData.stageId) {
          this.positionId = item.positionId
          this.ruleList = item.ruleList
        }
      })
    },
    async getPlatformStage() {
      const res = await getAction('platform/get_platform_stage', { id: this.parent.platformId })
      this.ruleList = []
      this.allRuleList = res.data
      res.data.forEach(item => {
        if (item.stageId == this.curRow.stageId) {
          this.positionId = item.positionId
          this.ruleList = item.ruleList
        }
      })
    },
    isDis(i) {
      if (this.formData.ruleList) {
        return this.formData.ruleList?.map(item => item.name).indexOf(i.name) == -1 ? false : true
      } else {
        return false
      }
    },
    closeRule(event, index) {
      this.formData.ruleList.splice(index, 1)
    },
    formSelectChange(id) {
      const item = { ...this.ruleList[this.ruleList?.map(item => item.id).indexOf(id)] }
      let ruleList = this.formData.ruleList || []
      let object = JSON.parse(JSON.stringify(item))
      ruleList.push(object)
      this.activeKey = id + ''
      this.formData = Object.assign({}, this.formData, { ruleList })
    },

    initForm() {
      this.formData = {
        id: null,
        productionId: null,
        positionId: '',
        positionName: '',
        predefinedTime: 0,
        rate: true,
        reversible: true,
        ruleId: null,
        sequenceNo: null,
        stageId: null,
        stageName: '',
        userId: '',
        userName: '',
        rejectList: [],
        kpiList: [],
        applyTranslation: 0,
        applyReview: 0
      }
      this.applyTranslation = false
      this.applyReview = false
    },

    async show() {
      console.log('显示...')
      // 通过获取平台阶段，得到平台阶段上的postitionId
      this.getPlatformStage()
      this.getPosition()
      this.getStageList()
      if (this.curRow.ruleList) {
        this.activeKey = this.curRow.ruleList[0].id + ''
      }
      this.rejectList = []
      this.kpiList = []
      this.rejectList = this.curRow.rejectList?.map(item => item.stageId)
      this.kpiList = this.curRow.kpiList?.map(item => item.stageId)
      if (this.curRow.applyTranslation) {
        this.applyTranslation = true
      } else {
        this.applyTranslation = false
      }
      if (this.curRow.applyReview) {
        this.applyReview = true
      } else {
        this.applyReview = false
      }
      const res = await getAction(`/production/stage/distinct/${this.productionId}`)
      if (res.code == 200) {
        this.dataSource = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    save() {
      if (!this.selectionRows.length) {
        this.$message.error('请先勾选数据')
      }
      const self = this
      this.setFormData()
      console.log('保存...')
      this.btnLoading = true
      console.log('新建URL', self.url.add)
      let arr = []
      this.selectionRows.forEach((row, rowIndex) => {
        let params = JSON.parse(JSON.stringify(row))
        params.productionId = this.productionId
        delete params.id
        delete params.departmentId
        arr.push(params)
      })
      postAction(self.url.add, arr).then(async res => {
        if (res.success) {
          self.$message.success(res.message || res.msg ? '保存成功' : '')
          self.isSaved = true
          self.btnLoading = false
          self.closed()
          this.selectionRows = []
          this.selectedRowKeys = []
        } else {
          self.btnLoading = false
          self.$message.error(res.message || res.msg)
        }
      })
    },
    setFormData() {
      this.formData.platformId = this.platformId
      this.formData.departId = this.parent.departmentId
      this.formData.productionId = this.parent.productionId
      if (this.formData.ruleList) {
        this.formData.ruleList?.forEach(item => {
          let newData = item ? JSON.parse(JSON.stringify(item)) : {}
          console.log(this.ruleList, 123)
          let oldData = this.ruleList[
            this.ruleList
              ?.map(i => {
                return i.name
              })
              .indexOf(item.name)
          ]
            ? JSON.parse(
                JSON.stringify(
                  this.ruleList[
                    this.ruleList
                      ?.map(i => {
                        return i.name
                      })
                      .indexOf(item.name)
                  ]
                )
              )
            : {}
          delete newData.id
          delete oldData.id
          delete newData.domain
          delete oldData.domain
          delete oldData.relationId
          delete newData.relationId
          delete oldData.hasUpdated
          delete newData.hasUpdated
          delete oldData.createTime
          delete newData.createTime
          delete oldData.createBy
          delete newData.createBy
          delete oldData.updateTime
          delete newData.updateTime
          delete oldData.updateBy
          delete newData.updateBy
          delete oldData.sequenceNo
          delete newData.sequenceNo
          oldData.kpiDeliveries?.forEach(oldItem => {
            delete oldItem.id
            delete oldItem.ruleId
            delete oldItem.gmtCreate
          })
          newData.kpiDeliveries?.forEach(newItem => {
            delete newItem.id
            delete newItem.ruleId
            delete newItem.gmtCreate
          })
          oldData.kpiErrors?.forEach(oldItem => {
            delete oldItem.id
            delete oldItem.ruleId
            delete oldItem.gmtCreate
          })
          newData.kpiErrors?.forEach(newItem => {
            delete newItem.id
            delete newItem.ruleId
            delete newItem.gmtCreate
          })
          oldData.levelRules?.forEach(oldItem => {
            delete oldItem.id
            delete oldItem.ruleId
            delete oldItem.averageId
            delete oldItem.quotaId
            delete oldItem.durationId
            delete oldItem.scoreId
            delete oldItem.quota?.id
            delete oldItem.duration?.id
            delete oldItem.average?.id
            delete oldItem.score?.id
            delete oldItem.quota?.unitLabel
            delete oldItem.duration?.unitLabel
            delete oldItem.average?.unitLabel
            delete oldItem.score?.gmtCreate
            delete oldItem.quota?.gmtCreate
            delete oldItem.duration?.gmtCreate
            delete oldItem.average?.gmtCreate
            delete oldItem.gmtCreate
          })
          newData.levelRules?.forEach(newItem => {
            delete newItem.id
            delete newItem.ruleId
            delete newItem.averageId
            delete newItem.quotaId
            delete newItem.durationId
            delete newItem.scoreId
            delete newItem.quota?.id
            delete newItem.duration?.id
            delete newItem.average?.id
            delete newItem.quota?.unitLabel
            delete newItem.duration?.unitLabel
            delete newItem.average?.unitLabel
            delete newItem.score?.id
            delete newItem.quota?.gmtCreate
            delete newItem.duration?.gmtCreate
            delete newItem.average?.gmtCreate
            delete newItem.score?.gmtCreate
            delete newItem.gmtCreate
          })
          oldData.difficulties?.forEach(oldItem => {
            delete oldItem.id
            delete oldItem.ruleId
            delete oldItem.factors
            delete oldItem.gmtCreate
            oldItem.categories?.forEach(o => {
              delete o.categoryId
              delete o.id
              delete o.rangeId
              delete o.typeId
              delete o.gmtCreate
              o.options?.forEach(oi => {
                delete oi.range?.id
                delete oi.categoryId
                delete oi.id
                delete oi.rangeId
                delete oi.gmtCreate
                delete oi.range?.gmtCreate
              })
              o.range?.forEach(oi => {
                delete oi.id
                delete ol.gmtCreate
              })
            })
          })
          newData.difficulties?.forEach(newItem => {
            delete newItem.id
            delete newItem.ruleId
            delete newItem.factors
            delete newItem.gmtCreate
            newItem.categories?.forEach(o => {
              delete o.categoryId
              delete o.id
              delete o.rangeId
              delete o.typeId
              delete o.gmtCreate
              o.options?.forEach(oi => {
                delete oi.range?.id
                delete oi.range?.id
                delete oi.categoryId
                delete oi.id
                delete oi.rangeId
                delete oi.gmtCreate
                delete oi.range?.gmtCreate
              })
              o.range?.forEach(oi => {
                delete oi.id
                delete ol.gmtCreate
              })
            })
          })
          oldData.kpiModifies?.forEach(oldItem => {
            delete oldItem.id
            delete oldItem.ruleId
            delete oldItem.rangeId
            delete oldItem.range?.id
            delete oldItem.range?.unitLabel
            delete oldItem.gmtCreate
            delete oldItem.range?.gmtCreate
          })
          newData.kpiModifies?.forEach(newItem => {
            delete newItem.id
            delete newItem.ruleId
            delete newItem.rangeId
            delete newItem.range?.id
            delete newItem.range?.unitLabel
            delete newItem.gmtCreate
            delete newItem.range?.gmtCreate
          })
          delete oldData.departments
          delete newData.departments
          delete oldData.positions
          delete newData.positions
          delete oldData.stages
          delete newData.stages
          console.log(newData, oldData, item.name, 213)
          oldData = JSON.stringify(oldData)
          newData = JSON.stringify(newData)
          newData != oldData && (item.hasUpdated = true)
          newData == oldData && (item.hasUpdated = false)
        })
      } else {
        this.formData.ruleList = []
      }
      this.formData.rejectList = []
      this.formData.kpiList = []
      this.rejectList?.forEach(item => {
        this.formData.rejectList.push({
          stageId: item
        })
      })
      this.kpiList?.forEach(item => {
        this.formData.kpiList.push({
          stageId: item
        })
      })
      if (this.applyTranslation) {
        this.formData.applyTranslation = 1
      } else {
        this.formData.applyTranslation = 0
      }
      if (this.applyReview) {
        this.formData.applyReview = 1
      } else {
        this.formData.applyReview = 0
      }
      this.formData.reversible = Boolean(this.formData.rejectList?.length)
      console.log(this.formData, 'formData')
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
    }
  }
}
</script>

<style lang="less" scoped>
.border-bottom {
  border-bottom: none;
}
/deep/ .ant-collapse {
  background-color: #fff !important;
  border: none !important;
  border-radius: 0 !important;
  width: 98.5%;
}
/deep/ .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-right: 0.6rem !important;
  font-size: 1rem !important;
}
/deep/ .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0;
}
/deep/ .ant-input-number-input {
  padding-right: 5px !important;
}
.ant-select-selection__rendered {
  img {
    display: none;
  }
}
.scroll-view {
  height: 80vh;
  overflow-y: auto;
}
</style>
